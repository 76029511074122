<template>
  <div class="companyInfo">
    <h3 class="title">企业设置</h3>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="公司标识:" prop="companyMark" ref="imgUrl">
        <upload-com @addImg="companyLogo" showImg="showImg" ref="uploadCom"></upload-com>
      </el-form-item>
      <el-form-item label="公司全称:" prop="companyName">
        <el-input v-model="form.companyName" placeholder="请输入公司全称"></el-input>
      </el-form-item>
      <el-form-item label="所在地区：" prop="selectedOptions" ref="selectedOptions">
        <el-cascader
          size="large"
          :options="options"
          v-model="form.selectedOptions"
          @change="handleChange"
          collapse-tags
        >
      </el-cascader>
      </el-form-item>
      <el-form-item label="公司地址:" prop="companyAddress">
        <el-input v-model="form.companyAddress"></el-input>
      </el-form-item>
      <el-form-item label="企业规模:" prop="companyScale" ref="companyScale">
          <el-select
            v-model="form.companyScale"
            placeholder="请选择">
            <el-option
              v-for="(item, index) in enterpriseList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="社会信用代码:" prop="socialCreditCode">
        <el-input v-model="form.socialCreditCode"></el-input>
      </el-form-item>
      <el-form-item label="联系人:" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入联系人"></el-input>
      </el-form-item>
      <el-form-item label="手机号码:" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <h6 class="supplierInfo">供应商信息 <span>请完善供应商信息为您精确匹配商机</span></h6>
      <el-form-item label="业务范围：" prop="businessScope">
          <el-select
            v-model="form.businessScope"
            multiple
            collapse-tags
            placeholder="请选择">
            <el-option
              v-for="(item, index) in businessList"
              :key="index"
              :label="item.taxonomyName"
              :value="item.taxonomyName">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="其他:">
        <el-input type="textarea" :rows="5" resize="none" v-model="form.others" placeholder="请输入其他业务范围"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width: 15%" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadCom from '@/components/upload/uploadCom.vue'
import { provinceAndCityData, CodeToText } from 'element-china-area-data'
import { getCompanyInfo, saveCompanyInfo } from '@/api/userInfo'
import { getBusiness, register } from '@/api/printing'

export default {
  data () {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        var reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的手机号'));
        }
      }
    }
    return {
      form: {
        companyMark: '',
        companyName: '',
        province: '',
        city: '',
        companyAddress: '',
        companyScale: '',
        socialCreditCode: '',
        nickname: '',
        phone: '',
        businessScope: ['数码印刷'],
        others: '',
        selectedOptions: [],
      },
      enterpriseList: ['1-50人', '50-100人', '100-500人', '500-1000人', '1000-9999人', '9999人以上'],
      id: '',
      userRoleId: '',
      businessList: [{taxonomyName: '数码印刷'}],
      options: provinceAndCityData,
      rules: {
        companyName: [
          { required: true, message: '请输入公司全称', trigger: 'blur' },
        ],
        companyMark: [
          { required: true, message: '请上传公司标识', trigger: 'change' },
        ],
        selectedOptions: [
          { required: true, message: '请选择所在地区', trigger: 'change' },
        ],
        companyAddress: [
          { required: true, message: '请输入公司地址', trigger: 'blur' },
        ],
        companyScale: [
          { required: true, message: '请选择企业规模', trigger: 'change' },
        ],
        socialCreditCode: [
          { required: true, message: '请输入社会信用代码', trigger: 'blur' },
        ],
        companyAddress: [
          { required: true, message: '请输入公司地址', trigger: 'blur' },
        ],
        companyAddress: [
          { required: true, message: '请输入公司地址', trigger: 'blur' },
        ],
        nickname: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        businessScope: [
          { required: true, message: '请选择业务范围', trigger: 'change' }
        ],
      }
    }
  },
  components: {
    UploadCom
  },
  created () {
    this.getScope()
  },
  methods: {
    companyLogo (data) {
      this.form.companyMark = data.fileUrl
      this.$refs.imgUrl.clearValidate()
    },
    handleChange (val) {
      if(val) {
        this.form.provinceCode = val[0]
        this.form.cityCode = val[1]
        this.form.provinceName = CodeToText[val[0]]
        this.form.cityName = CodeToText[val[1]]
      }
    },
    // 获取业务范围
    async getScope () {
      const res = await getBusiness({id: 39})
      if(res.code == 0) {
        this.businessList = [...res.data[0].childList, ...this.businessList]
        this.getData()
      }
    },
    // 获取信息
    async getData () {
      const res = await getCompanyInfo()
      if(res.code == 0) {
        this.form = JSON.parse(res.data.extInfo)
        this.$bus.$emit('setImgUrl', this.form.companyMark)
        this.id = res.data.id
        this.userRoleId = res.data.userRoleId
        if(this.form.businessScope.split(',').pop() == this.form.others) {
          this.form.businessScope = this.form.businessScope.split(',')
          this.form.businessScope.pop()
        }else {
          this.form.businessScope = this.form.businessScope.split(',')
        }
        this.$nextTick(() => {
          this.$refs.selectedOptions.clearValidate()
          this.$refs.companyScale.clearValidate()
        })
      }
    },
    // 保存
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form))
          form.businessScope.push(form.others)
          form.businessScope = form.businessScope.toString()
          const res = await saveCompanyInfo({id: this.id, userRoleId: this.userRoleId, extInfo: JSON.stringify(form)})
          if(res.code == 0) {
            this.$message.success('保存成功')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .companyInfo {
    padding: 20px;
    .title {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }
    /deep/ .el-input__inner {
      width: 500px;
    }
    /deep/ .el-textarea__inner {
      width: 500px;
    }
    .supplierInfo {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
      font-weight: 400;
      span {
        color: #999;
      }
    }
    /deep/ .avatar-uploader .el-upload {
      border: 1px dashed #ccc;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }
</style>