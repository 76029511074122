import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com',3000)
const http = new Request(process.env.VUE_APP_URL)

// 获取业务范围
export const getBusiness = params => {
  return http.get('/cube_web_api/taxonomy/selectAllTaxonomy', params)
}
// 注册
export const register = params => {
  return http.post('/cube_web_api/auth/printingHouse/login', params)
}
