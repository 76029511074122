var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "companyInfo" },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("企业设置")]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              ref: "imgUrl",
              attrs: { label: "公司标识:", prop: "companyMark" }
            },
            [
              _c("upload-com", {
                ref: "uploadCom",
                attrs: { showImg: "showImg" },
                on: { addImg: _vm.companyLogo }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司全称:", prop: "companyName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入公司全称" },
                model: {
                  value: _vm.form.companyName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "companyName", $$v)
                  },
                  expression: "form.companyName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              ref: "selectedOptions",
              attrs: { label: "所在地区：", prop: "selectedOptions" }
            },
            [
              _c("el-cascader", {
                attrs: {
                  size: "large",
                  options: _vm.options,
                  "collapse-tags": ""
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.selectedOptions,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "selectedOptions", $$v)
                  },
                  expression: "form.selectedOptions"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司地址:", prop: "companyAddress" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "companyAddress", $$v)
                  },
                  expression: "form.companyAddress"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              ref: "companyScale",
              attrs: { label: "企业规模:", prop: "companyScale" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.companyScale,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyScale", $$v)
                    },
                    expression: "form.companyScale"
                  }
                },
                _vm._l(_vm.enterpriseList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "社会信用代码:", prop: "socialCreditCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.socialCreditCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "socialCreditCode", $$v)
                  },
                  expression: "form.socialCreditCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人:", prop: "nickname" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系人" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码:", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号码" },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          ),
          _c("h6", { staticClass: "supplierInfo" }, [
            _vm._v("供应商信息 "),
            _c("span", [_vm._v("请完善供应商信息为您精确匹配商机")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "业务范围：", prop: "businessScope" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.form.businessScope,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "businessScope", $$v)
                    },
                    expression: "form.businessScope"
                  }
                },
                _vm._l(_vm.businessList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.taxonomyName,
                      value: item.taxonomyName
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "其他:" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  resize: "none",
                  placeholder: "请输入其他业务范围"
                },
                model: {
                  value: _vm.form.others,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "others", $$v)
                  },
                  expression: "form.others"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "15%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }